import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Gift } from '../components/gift'
import { Helm } from '../components/header'
import { Toast, Row, Col, Alert, Dropdown, DropdownButton } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/edwin.mp3'
import logoig from '../assets/img/dinny/logoig.svg'
import burung from '../assets/img/dinny/burung.svg'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'
import getData from '../params/getdata'
import covid from '../assets/img/edwin/covid.svg'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import bunga6 from '../assets/img/bunga6.png'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
let cmain = '#esf1f6'
let mainfont = '#7dc9e7'
let csub = " #7dc9e7"
let gold = "#B99225"
export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()
        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2,
            showgift: false,
            show: false,
            show1: false,
            show2: false,
            friend: 1
        }
    }
    async componentDidMount() {
        try {
            AOS.init({
                // initialise with other settings
                duration: 2000
            });
            var countDownDate = new Date("12/12/2020").getTime();
            // Update the count down every 1 second
            var x = setInterval(() => {
                // Get today's date and time
                var now = new Date().getTime();
                // Find the distance between now and the count down date
                var distance = countDownDate - now;
                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                this.setState({
                    days: days, hours: hours, minutes: minutes, seconds: seconds
                })
            }, 1000);
            let comment = await getData('kepada : "edwin-prisca"')
            let temp=[]
            for (let index = 0; index < comment.length; index++) {
                temp[index]=comment[comment.length-index-1]
            }
            comment=temp

            this.setState({ comment: comment })
            console.log(comment)
        } catch (error) {
            console.log(error)
        }
    }

    dropdown = () => {
        let { friend } = this.state
        let item = []
        for (let index = 1; index < 3; index++) {
            item[index] = (<Dropdown.Item onClick={() => {
                this.setState({ friend: index })
            }}>{index}</Dropdown.Item>)
        }
        return (
            <>
                <Item>
                    <p style={{ fontSize: '16px', color: cmain }} className="mb-0 w-100 text-center">
                        How many people you coming with?
          </p>
                    <DropdownButton id="dropdown-basic-button" title={friend == null ? '1' : friend}>
                        {
                            item
                        }
                    </DropdownButton>
                </Item>
            </>
        )
    }
    play = () => {

        var snd = new Audio(music);
        snd.type = 'audio/mp3';
        snd.play();
        AOS.refresh()
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)



    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}", jumlahhadir: "${this.state.friend}", kepada: "edwin-prisca", pesan:"${this.pesan.current.value}",alamat: ""`
                              )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                            
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }


    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, showgift, show, show1, show2, comment } = this.state
        let slide = [
            "	https://i.ibb.co/Q9xTB4p/Pict-1.jpg	",
            "	https://i.ibb.co/FqZ2Ns8/Pict-2.jpg	",
            "	https://i.ibb.co/6mv7Cxt/Pict-3.jpg	",
            "	https://i.ibb.co/89fY4Pq/Pict-4.jpg	",
            "	https://i.ibb.co/sjM3DJL/Pict-5.jpg	",
            "	https://i.ibb.co/vDk38r7/Pict-6.jpg	",

        ]
        let slider = []
        slide.map(v => {
            slider.push(gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=520'))
        })
        let query = this.useQuery().get('u');
        let time = this.useQuery().get('time');
        query = query ? cap(query) : ''


        return (
            <>
                <Helm
                    title='Undanganku - Edwin & Prisca'
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url='https://undanganku.me/edwin-prisca'
                />

                <div id='gold5' style={{
                    backgroundColor: cmain,
                    backgroundImage: `url('https://svgshare.com/i/S1f.svg')`,
                    backgroundSize: '25%', overflowX: 'hidden'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='position-relative p-0' style={{
                            backgroundImage: `url(${gambar(hide ? "https://i.ibb.co/PzMpBJL/Modal-1-1.jpg" : "https://i.ibb.co/HV1cKqT/Foto-cover-2.jpg", 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1800&h=900')})`
                        }}>
                            <div style={{ display: hide ? "block" : "none" }}>
                                <Item>
                                    <Col xs={12} md={4} className='m-2 m-md-0 '>
                                        <img className='img-fluid w-100 p-2'
                                            src={gambar('https://i.ibb.co/NCppdNk/Logo-4-1.png')}
                                            data-aos="fade-left" />
                                    </Col>
                                </Item>
                                <div style={{ position: 'absolute', bottom: '10%', width: '100%' }}>

                                    <h2 className={`roboto-slab text-center 
                                    pt-3 pt-sm-3 w-100`} style={{ marginTop: '0' }}>
                                        <div style={{ fontStyle: 'italic' }}>
                                            Dear :
                                    </div>
                                        <br /> <b>{query ? query : ''}</b> <br /></h2>

                                    <div className="w-100 text-center p-3">
                                        <div onClick={() => { this.play() }}

                                            className={`col-md-4 
                                        roboto-slab text-center btn ${hide ? 'show' : 'hide'}`}
                                            style={{
                                                marginTop: 0, color: 'white',
                                                border: '2px solid white',
                                                borderRadius: '10px',
                                                backgroundColor: 'rgba(255,255,255,0.3)'
                                            }}>
                                            Open Invitation
                                    </div>
                                    </div>



                                </div>
                            </div>


                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>

                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>
                                        <p className="fs24 text-center px-3" data-aos="fade-down"
                                            data-aos-duration="1000" data-aos-delay="100"
                                        >
                                            <span className="fs16" style={{ color: '#86835dff' }}>
                                                “Now unto Him that is able to do exceeding abundantly above all that we ask or think, according to the power that worketh in us.”  <br /> (Ephesians 3:20)
                                             </span>
                                        </p>
                                    </Item>
                                    <Container id='sectiongold57'>
                                        <div className='py-3' data-aos={`fade-left`} data-aos-duration="2000">
                                            <Item>

                                                <div data-aos="fade-left" data-aos-duration="1000"
                                                    className='col-10 col-lg-8 kotak' style={{ backgroundColor: csub }}>
                                                    <div className="px-5">
                                                        <img src='https://i.ibb.co/xX5pNWG/gif-Save-The-Date.gif' className="w-100 img-fluid my-3 px-2 px-md-5" />

                                                    </div>
                                                    <Item>
                                                        <div className='item'>
                                                            <Item>
                                                                <div>
                                                                    {days}
                                                                </div>
                                                            </Item>
                                                            <Item>
                                                                <span>
                                                                    Days
                                    </span>
                                                            </Item>
                                                        </div>
                                                        <div className='dot'>:</div>
                                                        <div className='item'>
                                                            <Item>
                                                                <div>
                                                                    {hours}
                                                                </div>
                                                            </Item>
                                                            <Item>
                                                                <span>
                                                                    Hours
                          </span>
                                                            </Item>
                                                        </div>
                                                        <div className='dot'>:</div>
                                                        <div className='item'>
                                                            <Item>
                                                                <div >
                                                                    {minutes}
                                                                </div>
                                                            </Item>
                                                            <Item>
                                                                <span>
                                                                    Mins
                          </span>
                                                            </Item>
                                                        </div>
                                                        <div className='dot' >:</div>
                                                        <div className='item'>
                                                            <Item>
                                                                <div>
                                                                    {seconds}
                                                                </div>
                                                            </Item>
                                                            <Item>
                                                                <span>
                                                                    Secs
                          </span>
                                                            </Item>
                                                        </div>
                                                    </Item>


                                                </div>

                                            </Item>
                                        </div>
                                    </Container>
                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: '#86835dff' }} data-aos={`fade-right`} data-aos-duration="2000">
                                            The Intimate Wedding of Edwin & Prisca
                                         </p>
                                    </Item>
                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >
                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'
                                                style={{ backgroundColor: 'rgba(255,255,255,0.2)' }}>
                                                <Item>
                                                    <h1 style={{
                                                        fontSize: '72px',
                                                        fontFamily: "'Marck Script', cursive", color: gold
                                                    }}>
                                                        Edwin
                      </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar('https://i.ibb.co/DWhhRfw/Edwin-Pric-248-1-1.jpg', 95)} className='img-fluid w-100 rounded-circle' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '36px',
                                                        fontFamily: "'Marck Script', cursive",
                                                        color: gold
                                                    }}>
                                                        Edwin Thian
                      </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#171717', fontStyle: 'normal' }}>
                                                        <b>The Son of: </b><br />
                            Mr. Thian Ga Tjang <br />
                        &<br />
                        Mrs. Yuliana Latif
                      </p>
                                                </Item>
                                                <Item>
                                                    <Col xs={3} sm={2}
                                                        onClick={() => { window.open('https://instagram.com/edwin.thian90') }}>
                                                        <img src={logoig} className='img-fluid w-100 btn p-0' />
                                                    </Col>
                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'
                                                style={{ backgroundColor: 'rgba(255,255,255,0.2)' }}>
                                                <Item>
                                                    <h1 style={{
                                                        fontSize: '72px',
                                                        fontFamily: "'Marck Script', cursive", color: gold
                                                    }}>
                                                        Prisca
                      </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar('https://i.ibb.co/X2DJ6SH/Edwin-Pric-248-1-4.jpg', 95)} className='img-fluid w-100 rounded-circle' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{
                                                            fontSize: '36px', fontFamily:
                                                                "'Marck Script', cursive", color: gold
                                                        }}>
                                                        Priscilla Tiwow
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#171717', fontStyle: 'normal' }}>
                                                        <b>The Daughter of : </b><br />
                                                            Mr. Victor Tiwow

                                                        <br />
                                                        &<br />
                                                        Mrs. Anita Ngantung
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <Col xs={3} sm={2}
                                                        onClick={() => { window.open('https://instagram.com/Priscapicha') }}>
                                                        <img src={logoig} className='img-fluid w-100 btn p-0' />
                                                    </Col>
                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: '#86835dff' }} >
                                    <Item>
                                        <p className="fs16" data-aos={`fade-right`} data-aos-duration="2000">
                                            The wedding will be held on :
                    </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20" data-aos={`fade-left`} data-aos-duration="2000">
                                            <b>
                                                Saturday <span className="fs36">12</span> DEC 2020
                      </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        {/* <p className="fs20 col-sm-4" style={{ color: '#86835dff' }}>
                      <b className="allura" style={{ fontSize: '46px' }}>Holy Matrimony</b><br />
                      <div style={{ fontSize: '14px' }}>
                        (family only)
                      </div>
                      <div className="py-3">
                        10.00 - finish
                       </div>
                      <br />
                      <span className=" fs16">
                        <b>
                          Santo Leo Agung Church<br />
                        </b>
                    Jl. Manunggal I, Cipinang Melayu, Jakarta Timur, 13620</span>
                    </p>
                    <p className="px-3 d-none d-sm-block" style={{ color: csub, fontSize: '72px' }}>
                      \
                    </p>
                    <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid #86835dff` }}>
                    </div> */}
                                        <p className="fs20 pt-3 pt-sm-0 w-100 text-center" style={{ color: '#86835dff' }} data-aos={`fade-right`} data-aos-duration="2000">
                                            <b className="allura" style={{ fontSize: '72px' }}> Intimate Reception</b><br />
                                            <div style={{ fontSize: '14px' }}>
                                            </div>
                                            <div className="py-3">
                                                18.30 - 21.00</div>
                                            <br />
                                            <span className=" fs16">
                                                <b>
                                                    Flamboyan Hall 2nd floor. <br />Central Restaurant Tomang
                    </b>
                                            </span>
                                        </p>
                                    </Item>
                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.6561499216486!2d106.79772914905496!3d-6.17676419550625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f662056ca6ed%3A0x1043d48fc6861a63!2sCentral!5e0!3m2!1sid!2sid!4v1607097209211!5m2!1sid!2sid" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://www.whatismyip-address.com/nordvpn-coupon/"></a></div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3} data-aos={`zoom-in`} data-aos-duration="2000"
                                            style={{
                                                border: `2px solid #86835dff`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open("https://g.page/CentralRestaurantTomang?share")
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg"
                                                    className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3"
                                                    style={{ color: '#86835dff' }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col data-aos={`zoom-in`} data-aos-duration="2000"
                                            onClick={() =>
                                                window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=MzRuNzVndmhmdWEyYzMyNGU2Z3ZicHI0cXAgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid #86835dff`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn ">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg"
                                                    className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3"
                                                    style={{ color: '#86835dff' }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>
                                <Container className="py-3">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={covid} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>
                                <Container className="text-center py-5 dinny" id="dinny">

                                    <>
                                        <Item>
                                            <h1 style={{
                                                fontSize: '72px',
                                                fontFamily: "'Marck Script', cursive",
                                                color: gold
                                            }} data-aos={`fade-right`} data-aos-duration="2000">
                                                Rundown
                                            </h1>
                                        </Item>

                                        {
                                            [
                                                // { waktu: "	09.00 -10.00	", acara: "	Akad Nikah", icon: "	https://www.flaticon.com/svg/static/icons/svg/2905/2905065.svg" },
                                                { waktu: "	18.30 - 18.45	", acara: "	Wedding Entrance	", icon: "	https://www.flaticon.com/svg/static/icons/svg/1110/1110062.svg	" },
                                                { waktu: "	18.45 - 19.00	", acara: "	Wedding cake Cutting & Pouring Sampagne	", icon: "	https://www.flaticon.com/svg/static/icons/svg/2675/2675483.svg	" },
                                                { waktu: "	19.00 - 20.30	", acara: "	Dinner	", icon: "	https://www.flaticon.com/svg/static/icons/svg/2424/2424721.svg	" },
                                                { waktu: "	19.00 - 21.00	", acara: "	Enjoy the Live Music	", icon: "	https://www.flaticon.com/svg/static/icons/svg/926/926338.svg	" },
                                                { waktu: "	20.00 - 20.45	", acara: "	Photo Session & Mingle ", icon: "	https://www.flaticon.com/svg/static/icons/svg/1473/1473885.svg	" },
                                                { waktu: '20.45 - 21.00', acara: 'Wedding bouquet tos', icon: 'https://www.flaticon.com/svg/static/icons/svg/2916/2916686.svg' }

                                            ].map((v, i) => {
                                                i++
                                                return (
                                                    <Row className="py-2" data-aos={`fade-left`} data-aos-duration="2000">
                                                        <Col className={i % 2 ? "order-md-1" : "order-md-2"}>
                                                            <Row >
                                                                <Col md={3} xs={3} className={`order-1 ${i % 2 ? "order-md-2" : "order-md-1"}`}>
                                                                    <img src={v.icon} className="img-fluid w-100 px-1 px-md-2 my-auto" />
                                                                </Col>
                                                                <Col xs={8} className={`order-1 text-left ${i % 2 ? "order-md-1 text-md-right" : "order-md-2 text-md-left"}`}>
                                                                    <p className=" fs16" style={{ color: gold }}>
                                                                        <b>
                                                                            {v.waktu}
                                                                        </b><br />
                                                                        {v.acara}
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className={`d-none d-md-block ${i % 2 ? "order-md-2" : "order-md-1"}`}>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }

                                    </>
                                </Container>


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000" data-aos={`fade-right`} data-aos-duration="2000">
                                    <Item>
                                        <div className="butterfly">
                                            <h1 style={{ color: '#86835dff', fontStyle: 'bold', fontWeight: 700, fontSize: '72px' }}>
                                                The Couple
                                        </h1>
                                        </div>
                                    </Item>
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{
                                                    backgroundColor: '#F2F2F2'
                                                }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4' style={{ color: gold }}>
                                                            Love is not just about finding
                                                        the <b>right person</b>, but creating <b>right relationship</b>.
                                                        It’s not about <b>how much love</b> you have in the beginning.
                                                        But how much Love you built till the <b>end</b>.
                              </p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>
                                <Container id='sectiongold58' className="mt-3 py-3" fluid style={{ backgroundColor: cmain }}>
                                    <Item>
                                        <Col xs={11}>
                                            <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                                <Item>
                                                    <Col xs={4} lg={2}>
                                                        <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <Col xs={12} md={6} data-aos={`fade-right`} data-aos-duration="2000">
                                                        <div className='col-12 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                            <Item>
                                                                <h1 style={{
                                                                    fontFamily: '"Marck Script", cursive',
                                                                    color: cmain
                                                                }}>
                                                                    Send Your Wishes
                              </h1>
                                                            </Item>
                                                            <Item>
                                                                <form className="col-12 w-100">
                                                                    <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' defaultValue={query ? query : ''} />
                                                                    <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                                    {
                                                                        this.dropdown()
                                                                    }
                                                                    <Item>
                                                                        <div id="formradio">
                                                                            <div class="custom_radio row justify-content-center">
                                                                                <div onClick={() => {
                                                                                    this.setState({ hadir: true })
                                                                                }
                                                                                }>
                                                                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                                    <label style={{ color: cmain }} for="featured-1">Hadir</label>
                                                                                </div>
                                                                                <div onClick={() => {
                                                                                    this.setState({ hadir: false })
                                                                                }
                                                                                } className="pl-5">
                                                                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                                    <label style={{ color: cmain }} for="featured-2"
                                                                                    >Tidak Hadir</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Item>

                                                                    <Item>

                                                                        <Col xs={12} className='' >
                                                                            {
                                                                                submitted == true ? (
                                                                                    <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                                        Pesan anda sudah disampaikan
                                                                                    </Alert>) : (submitted === false ? (
                                                                                        <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                            {
                                                                                                err.map(val => {
                                                                                                    return (
                                                                                                        <li>{val}</li>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </Alert>
                                                                                    ) : false)
                                                                            }

                                                                        </Col>
                                                                    </Item>
                                                                    <Item>
                                                                        <div className='btn col-6 button rounded'
                                                                            onClick={() => this.handleSubmit()} style={{ backgroundColor: mainfont }} no> Kirim </div>
                                                                    </Item>
                                                                </form>
                                                            </Item>
                                                        </div>

                                                    </Col>

                                                    <Col xs={12} md={6} data-aos={`fade-left`} data-aos-duration="2000">
                                                        <div className='col-12 kotak pb-4 pt-4 mt-3 mt-md-0' data-aos="left-left" data-aos-duration="1000" style={{ height: '500px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                            {comment ? (comment.map(v => {
                                                                return (
                                                                    <Item>
                                                                        <Col xs={2}>
                                                                            <img src='https://www.flaticon.com/svg/static/icons/svg/3447/3447670.svg' className="img-fluid w-100" />
                                                                        </Col>
                                                                        <Col xs={10}>
                                                                            <Alert variant="secondary col-12">
                                                                                <p style={{ fontSize: '16px' }} className='m-0'>
                                                                                    {v.pesan}
                                                                                </p>
                                                                            </Alert>
                                                                        </Col>
                                                                    </Item>
                                                                )
                                                            })) :
                                                                false
                                                            }
                                                        </div>
                                                    </Col>
                                                </Item>
                                            </div>

                                        </Col>
                                    </Item>
                                </Container>
                                <div data-aos-offset="-250"></div>
                                <Gift
                                    content={
                                        [
                                            {
                                                bank: 'Bank BCA',
                                                norek: '531 518 7319',
                                                nama: 'Edwin'
                                            },
                                            {
                                                bank: 'Bank BCA',
                                                norek: '0700 111 491',
                                                nama: 'Priscilla Theodora Tiwow'
                                            }
                                        ]
                                    }
                                    caption='For those who want to give gifts to our wedding, kindly transfer to the following accounts :'
                                    color={'rgb(134, 131, 93)'}
                                    bg={mainfont}
                                />


                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

